import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { LubeMainStyles } from './styles';
import OptimizedImage from '../../utils/OptimizedImage';
import smLightLogo from '../../images/sm-light-logo.svg';
import VideoPlayer from './video-player';

const LubeMain = () => {
  const { data } = useStaticQuery(getData);

  return (
    <LubeMainStyles>
      <div className="container lube-container">
        <div className="header">
          <OptimizedImage src={smLightLogo} alt="Shopmonkey Lube Page" />
          <p className="kicker">{data?.headingKicker}</p>
          <h1 className="heading">{data?.heading}</h1>
          <p className="subhead">{data?.subhead}</p>
        </div>
        {data?.video &&
          data.video.map((item, idx) => (
            <VideoPlayer
              youtubeUrl={item?.youtubeUrl}
              thumbNail={item?.videoThumbnail}
              heading={item?.heading}
              disclaimer={item?.disclaimer}
            />
          ))}
      </div>
    </LubeMainStyles>
  );
};

export default LubeMain;

const getData = graphql`
  {
    data: datoCmsLubePage(internalName: { eq: "Lube landing" }) {
      id
      internalName
      headingKicker
      heading
      subhead
      video {
        heading
        disclaimer
        youtubeUrl
        videoThumbnail {
          ...datoCmsWebsiteImage
        }
      }
    }
  }
`;
