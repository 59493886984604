import React, { useCallback, useState } from 'react';
import ModalVideo from 'react-modal-video';
import Icon from '../../styles/atoms/icons';
import { getYoutubeId } from '../../utils/getYoutubeId';
import OptimizedImage from '../../utils/OptimizedImage';

const VideoPlayer = ({ youtubeUrl, thumbNail, heading, disclaimer }) => {
  const youtubeId = getYoutubeId(youtubeUrl);
  const [play, setPlay] = useState(false);

  const playVideo = useCallback(() => setPlay(true), []);

  return (
    <>
      <div className="container video-container">
        <p className="heading">{heading}</p>
        <div className="video-wrapper">
          <div className="video-thumb">
            {thumbNail?.image?.gatsbyImageData ? (
              <OptimizedImage
                image={thumbNail?.image?.gatsbyImageData}
                alt="Video Thumbnail"
              />
            ) : (
              <OptimizedImage
                src={thumbNail?.image?.url}
                alt="Video Thumbnail"
              />
            )}
          </div>
          <div className="overlay">
            <div className="watch-video-btn">
              <span onClick={() => playVideo()} role="button" tabIndex={0}>
                <Icon id="FaPlay" />
              </span>
            </div>
          </div>
        </div>
        {disclaimer && <p className="helper-text mb-0">{disclaimer}</p>}
      </div>

      {typeof window !== 'undefined' && (
        <ModalVideo
          channel="youtube"
          isOpen={play}
          videoId={youtubeId}
          onClose={() => setPlay(false)}
        />
      )}
    </>
  );
};

export default VideoPlayer;
